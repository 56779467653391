<template>
  <v-dialog
    scrollable
    max-width="700"
    persistent
    :value="true"
  >
    <v-card>
      <v-card-text>
        <CategoriesForm
          @save="createCategory"
          :category="category"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    CategoriesForm: () => import('./CategoriesForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    isFormValid: true,
    category: {
      type: 1,
      status: true,
      name: null,
    },
  }),
  methods: {
    createCategory() {
      this.$api
        .post('product_categories', this.category)
        .then((res) => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Categoria cadastrada com sucesso',
            confirm: () => {
              if (this.category.type === 1) {
                this.$store.commit('setModal', {
                  show: true,
                  component: 'warning',
                  text: 'Deseja cadastrar as subcategorias agora?',
                  confirm: () => {
                    this.$router.push({
                      name: 'categories-edit',
                      params: { id: res.data.data.id },
                    })
                  },
                  negate: () => {
                    this.$router.push({ name: 'categories' })
                    this.$emit('updateList')
                  },
                })
              } else {
                this.$router.push({ name: 'categories' })
                this.$emit('updateList')
              }
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar cadastrar esta categoria',
            caption: 'Verifique se os campos estão preenchidos corretamente.',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
  },
}
</script>
